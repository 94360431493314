<template>
  <div class="container-2xl fluid w-100 p-0 m-0">
    <div class="row w-100 p-0 m-0" v-if="!noImages">
      <div class="col-xl-8">
        <div
          v-if="!loading"
          class="image-base image-showing mb-5"
          :style="'background-image: url(' + imageSelected + ');'"
        ></div>
        <b-skeleton v-else class="image-base image-showing mb-5"></b-skeleton>
      </div>
      <div class="p-0 m-0 col-xl-4">
        <div
          class="container fluid p-0 m-0 mx-auto align-h-right"
          v-if="!loading"
        >
          <div
            class="row mb-3 p-0 m-0"
            v-for="(arrayImges, indexArrayImages) in images"
            :key="indexArrayImages"
          >
            <div
              class="col-4"
              v-for="(image, imageKey) in arrayImges"
              :key="imageKey"
            >
              <div
                class="w-100 mini-image-render image-base p-0 m-0"
                :style="'background-image: url(' + image + ');'"
                @click="onClickImage(image)"
              ></div>
            </div>
          </div>
        </div>
        <div class="container fluid p-0 m-0 mx-auto align-h-right" v-else>
          <div
            class="row mb-3 p-0 m-0"
            v-for="indexArrayImages in 4"
            :key="indexArrayImages"
          >
            <div class="col-4" v-for="imageKey in 3" :key="imageKey">
              <b-skeleton
                class="w-100 image-base p-0 m-0"
                height="130px"
              ></b-skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row w-100 p-0 m-0 d-flex align-items-center justify-content-center"
      v-else
    >
      <p class="image-showing d-flex align-items-center justify-content-center">
        Empreendimento sem imagens
      </p>
    </div>
  </div>
</template>

<script>
const SIZE_GRID = 3;

export default {
  name: "ImagesGalery",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      images: [],
      imagens: [],
      imageSelected: "",
      noImages: false,
    };
  },
  methods: {
    onClickImage(image) {
      this.imageSelected = image;
    },
  },
  mounted() {
    const user = this.$session.get('master').visao_usuario;

    
    if(user === 'fundo_investimento') {
      this.imagens = this.$route.params.data.imagens;
    } else {
      this.imagens = this.$route.params.data.empreendimento.imagens;
    }

    const imagens_links = this.imagens.map((imagem) => imagem.link);

    if (imagens_links.length === 0) {
      this.noImages = true;
      return;
    }

    this.imageSelected = imagens_links[0];

    for (let i = 0; i < imagens_links.length; i += SIZE_GRID) {
      this.images.push(imagens_links.slice(i, i + SIZE_GRID));
    }
  },
};
</script>

<style lang="scss" scoped>
.image-base {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  border-radius: 5px;
}

.image-showing {
  width: 100%;
  height: 98%;
  min-height: 530px;

  transition: background-image 0.2s ease-in-out;
}

.mini-image-render {
  width: 100%;
  height: 130px;

  cursor: pointer;

  transition: transform 0.1s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}
</style>
