<template>
  <b-modal id="modal-accept-reject" hide-footer hide-header centered>
    <div class="d-flex flex-row w-100 m-0 p-0">
      <b-button
        class="ml-auto my-2 close-button"
        variant="link"
        @click="handleCloseModal"
      >
        <img src="../../../assets//icon_close.svg"
      /></b-button>
    </div>
    <div
      class="d-flex w-100 flex-column align-items-start justify-content-center gap-5"
    >
      <h4 class="title-modal">{{ acceptReject.title }} interesse</h4>
      <h5 class="question-modal">
        Tem certeza que deseja {{ acceptReject.title.toLowerCase() }} o
        interesse?
      </h5>
      <div
        class="d-flex w-100 flex-row align-items-end justify-content-center gap-5"
      >
        <b-button
          class="ml-auto"
          variant="outline-dark"
          :disabled="acceptReject.loading"
          @click="handleCloseModal"
          >Fechar</b-button
        >
        <b-button
          class="ml-3"
          variant="primary"
          :disabled="acceptReject.loading"
          @click="handleConfirmAcceptReject"
          >Confirmar</b-button
        >
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalAcceptReject",
  props: {
    acceptReject: Object,
    handleCloseModal: Function,
    handleConfirmAcceptReject: Function,
  },
};
</script>

<style lang="scss" scoped>
.close-button {
  cursor: pointer;

  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
    text-transform: none;
  }
}

.title-modal {
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: $neutral-95;

  margin-bottom: 18px;
}

.question-modal {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: $neutral-70;

  margin-bottom: 42px;
}
</style>
