<template>
  <div class="w-100 container-fluid m-0 p-0">
    <HeaderPanel :comesFrom="comesFrom" :user="user" :enterprise="enterprise" :status="status"/>
    <b-tabs
      active-nav-item-class="bg-transparent font-weight-bold border-bottom-5 border-top-0 border-left-0 border-right-0 nav-item-style"
      active-tab-class="bg-none"
      content-class="mt-3"
    >
      <b-tab title="Dados do Empreendimento" class="tab-item" active>
        <EnterpriseData />
      </b-tab>
      <b-tab title="Estatísticas" v-if="user.visao_usuario === 'incorporadora'">
        <Statistics />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Statistics from "@/views/enterprisesDetails/panels/Statistics.vue";
import EnterpriseData from "@/views/enterprisesDetails/panels/EnterpriseData.vue";
import HeaderPanel from "@/views/enterprisesDetails/components/HeaderPanel.vue";
import axios from "axios";

export default {
  name: "EnterprisesDetails",
  components: {
    Statistics,
    EnterpriseData,
    HeaderPanel,
  },
  data() {
    return {
      status: this.$route.params.status,
      enterprise: this.$route.params.data,
      enterpriseId: this.$route.params.id,
      user: {},
      comesFrom: this.$route.params.comesFrom
    };
  },
  async mounted() {
    const data = await this.$session.get("client");

    if (data) {
      this.user = data;
    }

    this.enterprise = this.$route.params;

    if (this.user.visao_usuario === 'fundo_investimento') {
       console.log("Salvando visualizacao do empreendimento");
      await this.handleUserView();
    }


  },
  methods: {
    async handleUserView() {

      try {
        return await axios
          .post(`/api/vitrine/fundo_investimento/painel_empreendimentos/visualizados`,
            {
              _id_empreendimento: this.enterpriseId
            },
            {
              headers: {
                id_cliente: this.$session.get("master").id_cliente,
              },
            })
          .then((response) => {
            return response.data;
          });

      } catch (error) {

        console.log(
          "Erro ao salvar visualizacao do empreendimento: ",
          error
        );
        return null;
      }
    },

  }
};

</script>

<style lang="scss" scoped>
::v-deep .nav-item-style {
  color: $main-blue !important;
  border-color: $main-blue !important;
  border-width: 2px !important;
}
</style>
s