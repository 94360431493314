<template>
  <b-dropdown
    dropleft
    variant="link-dark"
    type="button"
    role="button"
    aria-expanded="false"
    class="rounded"
  >
    <template v-slot:button-content>
      <img src="../../assets/icon-three-dots.svg" alt="Ações" />
    </template>
    <b-dropdown-item @click="handleAccept(data.item)">
      <p class="m-0 pb-1 border-bottom">Aceitar</p>
    </b-dropdown-item>
    <b-dropdown-item @click="handleReject(data.item)">
      <p class="text-danger p-0 m-0">Recusar</p>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: "ActionsTable",
  components: {},
  props: {
    data: Object,
    handleAccept: Function,
    handleReject: Function,
  },
};
</script>

<style lang="scss" scoped></style>
