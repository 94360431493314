<template>
  <div class="container-fluid m-0 p-0" v-if="loading">
    <div
      class="container-fluid m-0 p-0 d-flex flex-column"
      v-for="n in 5"
      :key="`skeleton-${n}`"
    >
      <b-skeleton width="100%" height="40px"></b-skeleton>
    </div>
  </div>
  <div v-else class="table-area">
    <b-table fixed :items="items" :fields="fields">
      <template v-slot:cell(acoes)="data">
        <ActionsTable
          v-if="data.item.status === 'interesse'"
          :data="data"
          :handleAccept="handleAccept"
          :handleReject="handleReject"
        />
      </template>
      <template v-slot:cell(status)="data">
        <span
          v-if="data.item.status === 'interesse'"
          class="badge-container waiting"
        >
          <img src="../../assets/clock-history.svg" alt="Aguardando" />
          Aguardando resposta</span
        >
        <span
          v-else-if="data.item.status === 'aceito'"
          class="badge-container accepted"
        >
          <img src="../../assets/accepted-green.svg" alt="Aceito" />
          Aceito
        </span>
        <span
          v-else-if="data.item.status === 'recusado'"
          class="badge-container rejected"
        >
          <img src="../../assets/rejected-red.svg" alt="Rejeitado" />
          Recusado
        </span>
      </template>
    </b-table>
  </div>
</template>

<script>
import ActionsTable from "@/components/ui/ActionsTable.vue";

export default {
  name: "Table",
  components: { ActionsTable },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    handleAccept: Function,
    handleReject: Function,
  },
  watch: {
    loading(loadingFalse) {
      if (!loadingFalse) {
        const tableactionsWidth = document.querySelector(".width-5");
        if (tableactionsWidth) tableactionsWidth.style.width = "10%";

        const tableActionsArrow = document.querySelectorAll(".dropdown-toggle");
        if (tableActionsArrow)
          tableActionsArrow.forEach((arrow) =>
            arrow.classList.remove("dropdown-toggle")
          );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .table-area .b-table .width-5 th {
  width: 10% !important;
}

.badge-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
}

.table-area {
  display: flex;
  width: 100%;

  overflow-x: auto;
}

.waiting {
  color: $orange;
}

.accepted {
  color: $green;
}

.rejected {
  color: $red;
}
</style>
