<template>
  <div class="container-fluid statistics-area">
    <FiltersStatistics :filters="filters" :updateValueItem="updateValueItem" @filter="wrapperLists"/>
    <div class="cards-area">
      <CardStatics
        v-for="(card, index) in cards"
        :key="index"
        :label="card.label"
        :value="card.value"
        :icon="card.icon"
        :loading="loading"
      />
    </div>
    <div class="container-fluid d-flex flex-column p-0 m-0">
      <b-tabs
        active-nav-item-class="bg-transparent font-weight-bold border-bottom-5 border-top-0 border-left-0 border-right-0 nav-item-style"
        active-tab-class="bg-none"
        content-class="mt-3"
      >
        <b-tab title="Interesses" active>
          <h4 class="title">Agentes que se interessaram</h4>
          <Table
            :fields="fieldsAgentesInteressados"
            :items="itemsAgentesInteressados"
            :loading="loading"
            :handleAccept="handleAccept"
            :handleReject="handleReject"
          />
        </b-tab>
        <b-tab title="Visualizações">
          <h4 class="title">Agentes que visualizaram</h4>
          <Table
            :fields="fieldsVisualizacoesSalvos"
            :items="itemVisualizacoes"
            :loading="loading"
          />
        </b-tab>
        <b-tab title="Salvos">
          <h4 class="title">Agentes que salvaram</h4>
          <Table
            :fields="fieldsVisualizacoesSalvos"
            :items="itemsSalvos"
            :loading="loading"
          />
        </b-tab>
      </b-tabs>
    </div>
    <ModalAcceptReject
      :handleCloseModal="handleCloseModal"
      :acceptReject="acceptReject"
      :handleConfirmAcceptReject="handleConfirmAcceptReject"
    />
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import Select from "@/components/ui/Select.vue";
import Table from "@/components/ui/Table.vue";

import FiltersStatistics from "@/views/enterprisesDetails/components/FiltersStatistics.vue";
import CardStatics from "@/views/enterprisesDetails/components/CardStatics.vue";
import ModalAcceptReject from "@/views/enterprisesDetails/components/ModalAcceptReject.vue";

export default {
  name: "Statistics",
  components: {
    Select,
    FiltersStatistics,
    CardStatics,
    Table,
    ModalAcceptReject,
  },
  data() {
    return {
      queryParams: URLSearchParams,
      acceptReject: {
        flag: "",
        title: "",
        item: {},
        loading: false,
      },
      filters: {
        personalizado_dados: "",
        data_inicio: "",
        data_fim: "",
      },
      cards: [
        {
          label: "Interesses",
          value: "x Agentes",
          icon: require("@/assets/interesses.svg"),
        },
        {
          label: "Visualizações",
          value: "x Vezes",
          icon: require("@/assets/visualizacoes.svg"),
        },
        {
          label: "Salvos",
          value: "x Vezes",
          icon: require("@/assets/salvos.svg"),
        },
      ],
      fieldsAgentesInteressados: [
        { key: "nome_agente", label: "Nome" },
        { key: "data", label: "Data" },
        { key: "status", label: "Status" },
        {
          key: "acoes",
          label: "Ações",
          class: "width-5",
          id: "_id_agente",
        },
      ],
      fieldsVisualizacoesSalvos: [
        { key: "nome_agente", label: "Nome" },
        { key: "data", label: "Data" },
      ],
      itemsAgentesInteressados: [],
      itemVisualizacoes: [],
      itemsSalvos: [],
      loading: true,
    };
  },
  methods: {
    filter() {
      const queryParams = new URLSearchParams();

      if (this.filters.data_inicio !== "") queryParams.set("data_inicial", this.filters.data_inicio);
      if (this.filters.data_fim !== "") queryParams.set("data_final", this.filters.data_fim);

      return queryParams
  },
    async wrapperLists() {
      this.loading = true;

      this.itemsAgentesInteressados = await this.getStatistics("interesses");
      this.itemVisualizacoes = await this.getStatistics("visualizados");
      this.itemsSalvos = await this.getStatistics("salvos");

      await this.updateValueCard();

      this.loading = false;
    },
    async getStatistics(type) {
      const url = `/api/vitrine/incorporadora/empreendimentos/${this.$route.params.id}/${type}?${this.filter().toString()}`;

      return await axios
        .get(url, {
          headers: { id_cliente: this.$session.get("master").id_cliente },
        })
        .then((response) => {
          if (response.data.length === 0) {
            return [];
          } else {
            // Format date
            response.data.forEach((item) => {
              item.data = moment(item.data.toString()).format(
                "DD/MM/YYYY à\\s HH:mm"
              );
            });

            return response.data;
          }
        })
        .catch((error) => {
          console.error("Erro ao fazer requisição de empreendimentos:", error);
          return [];
        });
    },
    updateValueItem(key, value) {
      this.filters[key] = value;
    },
    async updateValueCard() {
      this.cards = [
        {
          label: "Interesses",
          value: this.itemsAgentesInteressados.length + " Agentes",
          icon: require("@/assets/interesses.svg"),
        },
        {
          label: "Visualizações",
          value: this.itemVisualizacoes.length + " Vezes",
          icon: require("@/assets/visualizacoes.svg"),
        },
        {
          label: "Salvos",
          value: this.itemsSalvos.length + " Vezes",
          icon: require("@/assets/salvos.svg"),
        },
      ];
    },
    handleCloseModal() {
      this.$bvModal.hide("modal-accept-reject");
      this.acceptReject = {
        flag: "",
        title: "",
        item: {},
        loading: false,
      };
    },
    async handleAccept(item) {
      this.$bvModal.show("modal-accept-reject");
      this.acceptReject = {
        flag: "accept",
        title: "Aceitar",
        item: item,
        loading: false,
      };
    },
    async handleReject(item) {
      this.$bvModal.show("modal-accept-reject");
      this.acceptReject = {
        flag: "reject",
        title: "Rejeitar",
        item: item,
        loading: false,
      };
    },
    async handleConfirmAcceptReject() {
      this.acceptReject.loading = true;

      let response = null;

      if (this.acceptReject.flag === "accept") {
        response = await this.handleClickAcceptReject(
          "aceitar",
          this.acceptReject.item
        );
      } else {
        response = await this.handleClickAcceptReject(
          "recusar",
          this.acceptReject.item
        );
      }

      if (response === "OK") {
        const titleAcceptReject =
          this.acceptReject.flag === "accept" ? "Aceito" : "Rejeitado";

        this.$bvToast.toast(
          `Agente ${titleAcceptReject.toLowerCase()} com sucesso!`,
          {
            title: titleAcceptReject,
            autoHideDelay: 5000,
            variant: this.acceptReject.flag === "accept" ? "success" : "error",
            solid: true,
          }
        );

        this.acceptReject = {
          flag: "",
          title: "",
          item: {},
        };

        this.$bvModal.hide("modal-accept-reject");
        this.acceptReject.loading = false;

        this.wrapperLists();
      }
      if (response !== "OK") {
        this.$bvToast.toast(`Erro ao aceitar ou recusar interesse!`, {
          title: "Erro",
          autoHideDelay: 5000,
          variant: "error",
          solid: true,
        });

        this.$bvModal.hide("modal-accept-reject");
        this.acceptReject.loading = false;
      }
    },
    async handleClickAcceptReject(type, item) {
      try {
        return await axios
          .post(
            `/api/vitrine/incorporadora/empreendimentos/${type}`,
            {
              _id_empreendimento: this.$route.params.id,
              _id_fundo_investimento: item._id_agente,
            },
            {
              headers: {
                id_cliente: this.$session.get("master").id_cliente,
              },
            }
          )
          .then((response) => {
            return response.data;
          });
      } catch (error) {
        console.log("Erro ao aceitar interesse:", error);
        return null;
      }
    },
  },
  mounted: async function () {
    await this.wrapperLists();
  },
};
</script>

<style lang="scss" scoped>
.statistics-area {
  width: 100%;
  height: calc(100vh - 300px);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  overflow-y: auto;
  overflow-x: hidden;

  gap: 32px;

  /* Para navegadores Webkit (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 6px;
    /* Largura da barra de rolagem */
  }

  &::-webkit-scrollbar-thumb {
    background-color: $neutral-50;
    /* Cor da barra de rolagem */
    border-radius: 3px;
    /* Borda arredondada da barra de rolagem */
  }

  &::-webkit-scrollbar-track {
    background-color: #f2f2f2;
    /* Cor do fundo da barra de rolagem */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Cor da barra de rolagem */
    border-radius: 4px;
    /* Borda arredondada da barra de rolagem */
  }

  &::-webkit-scrollbar-track {
    background-color: #f2f2f2;
    /* Cor do fundo da barra de rolagem */
  }

  @media screen and (max-width: 1023px) {
    height: calc(100vh - 250px);
    padding: 0;
  }
}

.cards-area {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(550px, 1fr));

  gap: 16px;
}

.title {
  margin: 32px 0 24px 0;

  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: $neutral-95;

  text-transform: uppercase;
}

.dropdown-toggle::after {
  display: none !important;
}

::v-deep .nav-item-style {
  color: $main-blue !important;
  border-color: $main-blue !important;
  border-width: 2px !important;
}
</style>
import { over } from "lodash";
