var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"filters-area"},[_c('b-form-datepicker',{staticClass:"date",attrs:{"id":'data_inicio',"name":'data_inicio',"locale":"pt-BR","hideHeader":"","placeholder":'Início',"date-format-options":{
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },"right":"","icon":"calendar"},on:{"input":function($event){return _vm.updateValueItem('data_inicio', $event)}},model:{value:(_vm.personalizado_dados.data_inicio),callback:function ($$v) {_vm.$set(_vm.personalizado_dados, "data_inicio", $$v)},expression:"personalizado_dados.data_inicio"}}),_c('b-form-datepicker',{staticClass:"date",attrs:{"id":'data_fim',"name":'data_fim',"locale":"pt-BR","hideHeader":"","placeholder":'Fim',"date-format-options":{
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },"right":"","icon":"calendar"},on:{"input":function($event){return _vm.updateValueItem('data_fim', $event)}},model:{value:(_vm.personalizado_dados.data_fim),callback:function ($$v) {_vm.$set(_vm.personalizado_dados, "data_fim", $$v)},expression:"personalizado_dados.data_fim"}}),_c('button',{staticClass:"filter-button",on:{"click":_vm.filter}},[_vm._v("Filtrar")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }