<template>
  <div class="filters-area">
    <!--
    <div class="select-area">
      <Select
        :id="'personalizado'"
        :name="'personalizado'"
        :label="''"
        :options="personalizado_dados"
        :value="filters.personalizado_dados"
        @input="updateValueItem('personalizado', $event)"
        :optionNullValue="'Personalizado'"
      />
    </div>
    -->
    <b-form-datepicker
      class="date"
      :id="'data_inicio'"
      :name="'data_inicio'"
      v-model="personalizado_dados.data_inicio"
      locale="pt-BR"
      hideHeader
      :placeholder="'Início'"
      :date-format-options="{
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }"
      right
      icon="calendar"
      @input="updateValueItem('data_inicio', $event)"
    ></b-form-datepicker>
    <b-form-datepicker
      class="date"
      :id="'data_fim'"
      :name="'data_fim'"
      v-model="personalizado_dados.data_fim"
      locale="pt-BR"
      hideHeader
      :placeholder="'Fim'"
      :date-format-options="{
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }"
      right
      icon="calendar"
      @input="updateValueItem('data_fim', $event)"
    ></b-form-datepicker>
    <button 
      class="filter-button"
      @click="filter"
    >Filtrar</button>
  </div>
</template>

<script>
import Select from "@/components/ui/Select.vue";

export default {
  name: "FiltersStatistics",
  components: {
    Select,
  },
  props: {
    filters: {
      personalizado_dados: String,
      data_iniciso: String,
      data_fim: String,
    },
    updateValueItem: Function,
  },
  data() {
    return {
      personalizado_dados: [
        { value: "personalizado", label: "Personalizado" }
      ],
    };
  },
  methods: {
    filter() {
      this.$emit('filter');
  }
}
};
</script>

<style lang="scss" scoped>
.filters-area {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 16px;
}

.select-area {
  width: 100%;
  min-width: 210px;
}

.date {
  display: flex;
  flex-direction: row-reverse;

  width: 100%;
  padding: 9px 16px;

  border: 1px solid $neutral-50;
  border-radius: 6px;

  color: $neutral-95;

  font-size: 16px;
  font-weight: 400;
  box-sizing: border-box;
  transition: all 0.3s ease;

  outline: none;

  padding: 2px 0 2px 6px;
}

.filter-button {
  padding: 9px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-button:hover {
  background-color: #0056b3;
}

.filter-button:active {
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}
</style>
