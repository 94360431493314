<template>
  <div class="enterprise-detail-area">
    <!-- <ImagesGalery v-if="user === 'fundo_investimento'" img="" /> -->
    <ImagesGalery />
    <div class="content w-100">
      <div class="gap-5 row w-100">
        <div class="col-xl-6 w-100 mt-3">
          <TableDetails
            title="Dados Gerais"
            :data="
              user === 'fundo_investimento' ? generalData : generalDataMerge
            "
          />
        </div>
        <div class="col-xl-6 w-100 mt-3">
          <TableDetails
            title="Unidades"
            :data="user === 'fundo_investimento' ? unitsData : unitsDataMerge"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableDetails from "@/components/TableDetails.vue";
import ImagesGalery from "@/components/ImagesGalery.vue";
export default {
  components: { TableDetails, ImagesGalery },
  data() {
    return {
      enterpriseId: this.$route.params.id,
      enterprise: {},
      generalData: [],
      unitsData: [],
      generalDataMerge: [],
      unitsDataMerge: [],
      user: {},
      imagens: [],
      typeSegments: [
        { value: "special", label: "Especial (1 Dorm. até 40m²)" },
        { value: "economy", label: "Econômico (até R$ 264mil)" },
        { value: "standard", label: "Standard (R$ 264mil - R$ 500mil)" },
        { value: "medium", label: "Médio (R$ 500mil - R$ 1M)" },
        { value: "high", label: "Alto (R$ 1M - R$ 1.5M)" },
        { value: "lux", label: "Luxo (R$ 1.5M - R$ 3M)" },
        { value: "highlux", label: "Super Luxo (R$ 3M+)" },
      ],
      constructionPhaseOptions: [
        { label: "Busca de terreno", value: "busca-de-terreno" },
        { label: "Preparação", value: "preparacao" },
        { label: "Terraplanagem", value: "terraplanagem" },
        { label: "Escavação", value: "escavacao" },
        { label: "Fundação", value: "fundacao" },
        { label: "Estrutura", value: "estrutura" },
        { label: "Alvenaria", value: "alvenaria" },
        { label: "Acabamento", value: "acabamento" },
        { label: "Entrega", value: "entrega" }
      ],
      porte_obraOptions: [
        { value: "small", label: "Pequeno" },
        { value: "medium", label: "Médio" },
        { value: "large", label: "Grande" },
      ],
    };
  },
  mounted: async function () {
    this.user = this.$session.get("master").visao_usuario;
    this.enterprise = this.$route.params;
    const { data } = this.enterprise;

    if (this.user === "fundo_investimento") {

      const porte_obra = (data.incorporadora.porte_obra_tamanho ? this.porte_obraOptions.find( obj =>
                            obj.value == data.incorporadora.porte_obra_tamanho.toString()
                          ).label : "");

      const segmento = (data.segmento_atuacao[0] ? this.typeSegments.find( obj =>
                          obj.value == data.segmento_atuacao[0].toString()
                        ).label : "");

      const fase_obra = (data.fase_obra[0] ? this.constructionPhaseOptions.find( obj =>
                          obj.value == data.fase_obra[0].toString()
                        ).label : "");

      this.generalData = [
        {
          title: "Porte Incorporadora",
          value: porte_obra,
        },
        {
          title: "Tipo de empreendimento",
          value: data.tipo_empreendimento?.toString(),
        },
        {
          title: "Segmento",
          value: segmento,
        },
        {
          title: "Fase da obra",
          value: fase_obra,
        },
        {
          title: "Avanço geral da obra",
          value: `${data.avanco_geral_obra}%`,
        },
        {
          title: "Custo estimado",
          value: this.currencyFormat(data.custo_estimado_reais) || ''
        },
        {
          title: "VGV",
          value: this.currencyFormat(data.vgv_reais) || ''
        },
      ];

      this.unitsData = [
        {
          title: "Total (unidades)",
          value: data.numero_unidades,
        },
        {
          title: "Área (m²)",
          value: data.area_unidades_m2[0]?.toString().replace(/[m²]/g, ""),
        },
        {
          title: "Ticket Médio",
          value: this.currencyFormat(data.ticket_medio) || ''
        },
        {
          title: "Vendidas (unidades)",
          value: data.unidades_vendidas,
        },
        {
          title: "Estoque (unidades)",
          value: data.estoque,
        },
      ];
    } else {

      const segmento = (data.empreendimento.segmento_atuacao[0] ? this.typeSegments.find( obj =>
                          obj.value == data.empreendimento.segmento_atuacao[0].toString()
                        ).label : "");

      const fase_obra = (data.empreendimento.fase_obra[0]? this.constructionPhaseOptions.find( obj =>
                          obj.value == data.empreendimento.fase_obra[0].toString()
                        ).label : "");


      this.generalDataMerge = [
        {
          title: "Tipo de empreendimento",
          value: data.empreendimento.tipo_empreendimento[0]?.toString(),
        },
        {
          title: "Segmento",
          value: segmento,
        },
        {
          title: "Fase da obra",
          value: fase_obra,
        },
        {
          title: "Avanço geral da obra",
          value: `${data.empreendimento.avanco_geral_obra}%`,
        },
        {
          title: "Custo estimado",
          value: this.currencyFormat(data.empreendimento.custo_estimado_reais)
        },
        {
          title: "VGV",
          value: this.currencyFormat(data.empreendimento.vgv_reais) || ''
        }
      ];

      this.unitsDataMerge = [
        {
          title: "Total (unidades)",
          value: data.empreendimento.numero_unidades,
        },
        {
          title: "Área (m²)",
          value: data.empreendimento.area_unidades_m2[0]?.toString().replace(/[m²]/g, ""),
        },
        {
          title: "Ticket Médio",
          value:  this.currencyFormat(data.empreendimento.ticket_medio) || ''
        },
        {
          title: "Vendidas (unidades)",
          value: data.empreendimento.unidades_vendidas,
        },
        {
          title: "Estoque (unidades)",
          value: data.empreendimento.estoque,
        }
      ];
    }
  },
  methods: {
    currencyFormat(value) {
        if (!value) return "";
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(value);
    },
  }
};
</script>

<style lang="scss" scoped>
.enterprise-detail-area {
  width: 100%;
  height: calc(100vh - 300px);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  overflow-y: auto;

  /* Para navegadores Webkit (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 6px; /* Largura da barra de rolagem */
  }

  &::-webkit-scrollbar-thumb {
    background-color: $neutral-50; /* Cor da barra de rolagem */
    border-radius: 3px; /* Borda arredondada da barra de rolagem */
  }

  &::-webkit-scrollbar-track {
    background-color: #f2f2f2; /* Cor do fundo da barra de rolagem */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888; /* Cor da barra de rolagem */
    border-radius: 4px; /* Borda arredondada da barra de rolagem */
  }

  &::-webkit-scrollbar-track {
    background-color: #f2f2f2; /* Cor do fundo da barra de rolagem */
  }

  @media screen and (max-width: 1023px) {
    height: calc(100vh - 250px);
    padding: 0;
  }
}
.content {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 64px !important;
}
</style>
