<template>
  <div class="d-flex flex-column gap-2 w-100">
    <h4 class="title-table text-uppercase">{{ title }}</h4>
    <ul class="list-group list-group-flush">
      <li
        v-if="loading"
        v-for="index in data"
        :key="index"
        class="list-group-item border-0 w-100 py-auto p-0"
      >
        <div class="list-items h-100 w-100 d-flex p-0">
          <span class="title-area w-25 py-2 px-0 text-table px-2">
            <b-skeleton
              animation="wave"
              width="100%"
              height="20px"
              class="rounded-0"
            />
          </span>
          <span class="py-2 px-2 w-75 text-table contant-table">
            <b-skeleton
              animation="wave"
              width="100%"
              height="20px"
              class="rounded-0"
            />
          </span>
        </div>
      </li>
      <li
        v-if="!loading"
        v-for="(values, index) in data"
        :key="index"
        class="list-group-item border-0 w-100 py-auto p-0"
      >
        <div v-if="values.title['custo_estimado_reais']" class="list-items h-100 w-100 d-flex p-0">
          <span class="title-area w-25 py-2 px-0 text-table">{{
            values.title
          }}</span>
          <span class="py-2 px-2 w-75 text-table contant-table">{{
            currencyFormat(values.value)
          }}</span>
        </div>
        <div v-else class="list-items h-100 w-100 d-flex p-0">
          <span class="title-area w-25 py-2 px-0 text-table">{{
            values.title
          }}</span>
          <span class="py-2 px-2 w-75 text-table contant-table">{{
            values.value
          }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TableDetails",
  props: {
    title: String,
    data: [Object, Array],
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currencyFormat() {
      console.log('aquiii?')
      return (value) => {
        if (!value) return "";
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(value);
      };
    },
  },
  created() {
    console.log(this.data, "data")
  },
};
</script>

<style lang="scss" scoped>
.title-table {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: $purple;
}

.list-group-item {
  box-sizing: border-box;
  height: fit-content;
  background: $background;

  &:nth-child(odd) {
    .list-items > span:not(.title-area) {
      background-color: $neutral-20;
    }
  }
}

.text-table {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: $neutral-70;
}

.contant-table {
  font-weight: 700;
  color: $neutral-95;
}
</style>
