<template>
  <header class="d-flex flex-column justify-between w-100 header">
    <div class="d-flex flex-row justify-between w-100 top-header-content">
      <b-button class="button-back m-0 p-0" variant="link" @click="handleClickBack">
        <img src="../../../assets/arrow-back-enterprise.svg" alt="Voltar" />
        Voltar para {{ pageComesBack }}
      </b-button>
      <p class="ml-auto name-company-client">
        <span class="client-name">{{ user.nome }}</span>
        <span>|</span>
        <span> {{ user.razao_social }}</span>
      </p>
    </div>
    <div class="d-flex w-100 flex-row justify-content-between align-items-center m-0 p-0">
      <div class="d-flex flex-column gap-1 items-start justify-content-start">
        <b-skeleton v-if="loading" animation="wave" width="450px" height="40px" class="rounded-0" />
        <h1 class="title" v-else>
          {{ nome_empreendimento }}
          <span class="subtitle">| {{ tipo_empreendimento }}</span>
        </h1>
        <b-skeleton v-if="loading" animation="wave" width="450px" height="20px" class="rounded-0 mt-1" />
        <div v-else class="d-flex flex-row gap-5 align-items-center justify-content-start">
          <img src="../../../assets/geo-black.svg" alt="Geo" class="mr-2" />
          <h3 class="location m-0">Vila Olímpia - São Paulo, SP</h3>
        </div>
      </div>
      <div v-if="loading">
        <b-skeleton animation="wave" width="200px" height="41px" class="rounded-0" />
      </div>
      <div v-else>
        <b-button v-if="visao_usuario === 'incorporadora'" variant="outline-dark" class="px-3 py-2 m-0 gap-2"
          @click="handleClickEdit">
          <img src="../../../assets/edit-pen.svg" alt="Editar" />
          <span class="ml-2">Editar Dados</span></b-button>
        <div v-else class="d-flex align-items-center justify-content-end">
          <div class="ml-3 px-3 py-2 gap-2 d-flex align-items-center justify-content-end badge-area accepted"
            v-if="status === 'aceitos'">
            <img src="../../../assets/check-green.svg" alt="aceitos" />
            <span>Interesse aceito</span>
          </div>
          <div class="ml-3 px-3 py-2 gap-2 d-flex align-items-center justify-content-end badge-area waiting"
            v-else-if="status === 'interesses'">
            <img src="../../../assets/clock-history.svg" alt="Aguardando" />
            <span>Aguardando resposta do interesse</span>
          </div>
          <div v-else class="d-flex align-items-center justify-content-end">
            <b-button variant="outline-dark" class="px-3 py-2 m-0 gap-2 d-flex align-items-center justify-content-end"
              @click.stop="handleClickSave($event)" :disabled="salvando">

              <b-spinner class="mr-2" small type="grow" v-if="salvando"></b-spinner>

              <img class="mr-2" src="../../../assets/mark.svg" alt="Salvar" v-if="!enterprise.data.salvo && !salvando" />
              <img class="mr-2" src="../../../assets/mark-saved.svg" alt="Salvar" v-if="enterprise.data.salvo && !salvando" />

              <span v-if="!enterprise.data.salvo">Salvar</span>
              <span  v-if="enterprise.data.salvo">Salvo</span>
            </b-button>
            <b-button variant="primary" class="ml-3 px-3 py-2 gap-2 d-flex align-items-center justify-content-end"
              @click.stop="handleDemonstrateInterest($event)" :disabled="demonstrando_interesse">

              <b-spinner small type="grow" v-if="demonstrando_interesse"></b-spinner>

              <img src="../../../assets/build-check.svg" alt="Visualizar" v-if="!demonstrando_interesse" />

              <span class="ml-2" v-if="!enterprise.data.interesse">Demonstrar interesse</span>
              <span class="ml-2" v-if="enterprise.data.interesse">Remover interesse</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import axios from "axios";

export default {
  name: "HeaderPanel",
  components: {},
  props: {
    user: {
      type: Object,
      default: '',
    },
    enterprise: {
      type: Object,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    comesFrom: {
      type: String,
      default: 'enterprise',
    },
  },
  data() {
    return {
      isKey: 0,
      visao_usuario: '',
      loading: true,
      empreendimento_id: '',
      nome_empreendimento: '',
      tipo_empreendimento: '',
      salvando: false,
      demonstrando_interesse: false,
      pageComesBack: this.comesFrom === "search" ? "buscar empreendimento" : "meus empreendimentos",
    };
  },
  methods: {
    handleClickBack() {
      this.$router.go(-1);
    },
    async handleClickSave(event) {

      this.salvando = true;

      if (!this.enterprise.data.salvo) {

        try {

          await axios
            .post("/api/vitrine/fundo_investimento/painel_empreendimentos/salvos",
              { _id_empreendimento: this.empreendimento_id },
              {
                headers: {
                  id_cliente: this.$session.get("master").id_cliente,
                },
              })
            .then((response) => {
              this.enterprise.data.salvo = true;
              this.salvando = false;
              // this.$bvModal.show("modal-accept-reject");
            });
        } catch (error) {
          this.salvando = false;
          console.log(
            "Erro ao adicionar salvo:",
            error
          );
        }
      }
      else {

        try {

          await axios
            .delete(`/api/vitrine/fundo_investimento/painel_empreendimentos/salvos/${this.empreendimento_id}`,
              {
                headers: {
                  id_cliente: this.$session.get("master").id_cliente,
                },
              })
            .then((response) => {
              this.enterprise.data.salvo = false;
              this.salvando = false;
              // this.$bvModal.show("modal-accept-reject");
            });
        } catch (error) {
          this.salvando = false;
          console.log(
            "Erro ao remover salvo:",
            error
          );
        }
      }
    },
    async handleDemonstrateInterest(event) {

      this.demonstrando_interesse = true;

      if (!this.enterprise.data.interesse) {

        try {

          await axios
            .post("/api/vitrine/fundo_investimento/painel_empreendimentos/interesses",
              { _id_empreendimento: this.empreendimento_id },
              {
                headers: {
                  id_cliente: this.$session.get("master").id_cliente,
                },
              })
            .then((response) => {
              this.enterprise.data.interesse = true;
              this.demonstrando_interesse = false;
              // this.$bvModal.show("modal-accept-reject");
            });
        } catch (error) {
          this.demonstrando_interesse = false;
          console.log(
            "Erro ao adicionar interesse:",
            error
          );
        }
      }
      else {

        try {

          await axios
            .delete(`/api/vitrine/fundo_investimento/painel_empreendimentos/interesses/${this.empreendimento_id}`,
              {
                headers: {
                  id_cliente: this.$session.get("master").id_cliente,
                },
              })
            .then((response) => {
              this.enterprise.data.interesse = false;
              this.demonstrando_interesse = false;
              // this.$bvModal.show("modal-accept-reject");
            });
        } catch (error) {
          this.demonstrando_interesse = false;
          console.log(
            "Erro ao remover interesse:",
            error
          );
        }
      }
    },
    handleClickEdit() {
      //TODO - Implementar clicar em editar
    },
  },
  mounted: async function () {
    this.visao_usuario = await this.$session.get("master").visao_usuario

    if (this.visao_usuario === 'incorporadora') {
      this.nome_empreendimento = this.enterprise.empreendimento.nome_empreendimento;
      this.tipo_empreendimento = this.enterprise.empreendimento.tipo_empreendimento[0];
    } else {
      this.empreendimento_id = this.enterprise._id;
      this.nome_empreendimento = this.enterprise.nome_empreendimento;
      this.tipo_empreendimento = this.enterprise.tipo_empreendimento[0];
    }

    setTimeout(() => {
      if (this.visao_usuario !== null) {
        this.loading = false;
      }
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 7px;
}

.top-header-content {
  height: fit-content;
  margin-bottom: 21px;
}

.button-back {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: $orange;

  transition: all 0.8 ease-in-out;

  &:hover {
    text-decoration: none;
    opacity: 0.7;
  }
}

.name-company-client {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 11px;

  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: $neutral-95;
}

.client-name {
  font-weight: 600;
}

.title {
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: $neutral-95;
}

.subtitle {
  font-weight: normal;
}

.location {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: $neutral-95;
}

.badge-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border-radius: 6px;
  padding: 2px, 24px;
  gap: 8px;

  >span {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
  }
}

.accepted {
  background: #ecf9f3;
  border: 1px solid #277c5a;

  >span {
    color: #277c5a;
  }
}

.waiting {
  background: #fef7f6;
  border: 1px solid #e85026;

  >span {
    color: #e85026;
  }
}
</style>
