<template>
  <div class="card" v-if="loading">
    <b-skeleton width="100%" height="100%"></b-skeleton>
  </div>
  <div class="card" v-else>
    <div class="icon">
      <img :src="icon" alt="icon" />
    </div>
    <div class="content">
      <span class="label">{{ label }}</span>
      <span class="value">{{ value }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardStatics",
  props: {
    label: String,
    value: String,
    icon: String,
    loading: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 121px;
  flex-grow: 1;

  gap: 16px;
  padding: 32px;
  border-radius: 12px;
  background-color: $neutral-00;
}

.icon {
  width: 56px;
  height: 56px;
  flex-grow: 0;
  border-radius: 8px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  gap: 4px;
}

.label {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-transform: uppercase;
  text-align: left;
  color: $neutral-70;
}

.value {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: $neutral-95;
}
</style>
